import React from "react";
import { graphql } from "gatsby";
import "./ProjectList.scss";
import { Pagination } from "../../Pagination";
import { PostListItem } from "../../Blog/PostListItem";
import { ProjectListNavigation } from "../ProjectListNavigation";

export const ProjectList = ({ posts, pageContext, pathPrefix, categories }) => {
  return (
    <section className="project-list layout">
      <div className="project-list__wrapper">
        <h2 className="heading">Our Projects</h2>
        <ProjectListNavigation categories={categories} />
        <div className="project-list-grid">
          {Object.keys(posts).length > 0 &&
            posts?.map((post, index) => {
              return <PostListItem key={index} data={post.node} />;
            })}
        </div>
        <Pagination pageContext={pageContext} pathPrefix={pathPrefix} />
      </div>
    </section>
  );
};

export const pageQuery = graphql`
  fragment ProjectListFields on wordpress__wp_projects {
    id
    project_category
    title
    excerpt
    date(formatString: "MMMM Qo gggg")
    slug
    path
    wordpress_id
    featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 670, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`;
