import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { Header } from "../components/Acf/Header";
import { ContactBlock } from "../components/Acf/ContactBlock";
import { ProjectList } from "../components/Projects/ProjectList";
import { decodeEntities } from "../utils/helpers";

const ProjectIndexPage = (props) => {
  const { data, pageContext, location, path } = props;
  const {
    wordpressPost: page = [],
    allWordpressWpProjects,
    categoryFilter,
    categories,
    wordpressCategory,
    wordpressWpSettings,
    siteSettings,
  } = data;
  const { options = null } = siteSettings;
  const { wordpressUrl } = wordpressWpSettings;
  const {
    title,
    yoast,
    childWordPressAcfHeader = null,
    childWordPressAcfContactBlock = null,
  } = page ? page : { title: null, yoast: {}, acf: {} };

  const { title: siteTitle } = wordpressWpSettings;
  const { edges: posts } = allWordpressWpProjects;

  return (
    <>
      <SEO
        title={`${
          yoast.metaTitle
            ? yoast.metaTitle
            : `Projects | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
        yoast={yoast}
        location={location}
      />
      <Header {...childWordPressAcfHeader} />
      <ProjectList
        posts={posts}
        title="Latest projects"
        pageContext={pageContext}
        siteMetadata={wordpressWpSettings}
        categories={categories.edges}
        pathPrefix={"/portfolio/"}
      />
      <ContactBlock {...childWordPressAcfContactBlock} />
    </>
  );
};

export default ProjectIndexPage;

export const pageQuery = graphql`
  query ProjectIndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
    wordpressPost: wordpressPage(slug: { eq: "portfolio" }) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      childWordPressAcfHeader {
        title
        content
        carousel {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1150, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        homepage
        theme
      }
      childWordPressAcfContactBlock {
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        imagePosition
        content
        squareAccent
      }
    }
    wordpressCategory: wordpressWpProjectCategory(
      slug: { eq: "uncategorised" }
    ) {
      name
      slug
      path
      description
    }
    allWordpressWpProjects(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
      filter: { slug: { ne: "gatsby-demo" } }
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
        }
      }
    }
  }
`;
