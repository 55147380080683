import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { decodeEntities } from "../../../utils/helpers";
//import '../PostListNavigation.scss'

export const ProjectListNavigation = (props) => {
  const { categories = [], placeholderText = "Filter" } = props;
  const [currentPath, setCurrentPath] = useState(
    typeof window !== "undefined" ? window.location.pathname : null
  );
  const [postType, setPostType] = useState("");

  // on load gets the URL location, for example /blog/ or /projects/
  // will need tweaking if the URL structure is slightly different
  useEffect(() => {
    let pathArray = currentPath.split("/");
    pathArray = pathArray.filter(Boolean);
    setPostType(pathArray[0]);
  }, [currentPath]);

  const isActiveCategory = (categories) => {
    // works out whether the value is the current path
    if (typeof window !== "undefined") {
      // is the current path in the dropdown items
      const result = categories.filter((o) =>
        currentPath.includes(o.node.slug)
      );

      if (
        currentPath.split("/").filter(Boolean).length > 1 &&
        result.length > 0
      ) {
        return currentPath;
      } else {
        return "default";
      }
    }
  };
  const alphabetise = categories.sort((a, b) =>
    a.node.name > b.node.name ? 1 : b.node.name > a.node.name ? -1 : 0
  );

  return (
    <select
      className="filter--dropdown"
      onChange={(event) => {
        event.preventDefault();
        navigate(event.target.value);
      }}
      defaultValue={isActiveCategory(categories)}
      value={isActiveCategory(categories)}
    >
      <option
        className="filter--dropdown--item"
        selected
        disabled
        value="default"
      >
        {placeholderText}
      </option>
      <option value={`/${postType}/`} className="filter--dropdown--item">
        All
      </option>
      {/* Maps through all categories and returns the ones which aren't "uncategorised" */}
      {Object.keys(alphabetise).length > 0 &&
        alphabetise.flatMap((item, index) => {
          return (
            item.node.slug !== "uncategorised" && (
              <option
                value={`/${postType}/${item.node.slug}/`}
                key={`${item.id}-${index}`}
                className="filter--dropdown--item"
              >
                {decodeEntities(item.node.name)}
              </option>
            )
          );
        })}
    </select>
  );
};
